import "./page-banner.scss";

import { bool, node, string } from "prop-types";

import cx from "classnames";
import useAOS from "../../common/useAOS.js";

const propTypes = {
  title: string.isRequired,
  description: node,
  accent: bool,
  hasBackground: bool,
};

const defaultProps = { accent: true, hasBackground: true };

const PageBanner = (props) => {
  const { title, description, accent, hasBackground } = props;

  useAOS();

  return (
    <section
      className={cx("page-banner container", {
        "container--bleed container--has-background": hasBackground,
      })}
    >
      <div className="page-banner__content-wrapper">
        <h1
          data-aos="fade-down"
          data-aos-offset="0"
          className="page-banner__title"
        >
          {title}
        </h1>
        {description && (
          <div
            data-aos="fade-down"
            data-aos-offset="0"
            className="page-banner__description"
          >
            {description}
          </div>
        )}
      </div>
      {accent && (
        <div aria-hidden className="page-banner__accent">
          <div
            data-aos="slide-right"
            data-aos-duration="1000"
            data-aos-delay="600"
            data-aos-offset="0"
            className="page-banner__accent-bar page-banner__accent-bar--1"
          >
            <div />
          </div>
          <div
            data-aos="slide-right"
            data-aos-duration="1000"
            data-aos-delay="300"
            data-aos-offset="0"
            className="page-banner__accent-bar page-banner__accent-bar--2"
          >
            <div />
          </div>
          <div
            data-aos="slide-right"
            data-aos-duration="1000"
            data-aos-offset="0"
            className="page-banner__accent-bar page-banner__accent-bar--3"
          >
            <div />
          </div>
        </div>
      )}
    </section>
  );
};

PageBanner.defaultProps = defaultProps;
PageBanner.propTypes = propTypes;
export default PageBanner;
