import "./partners-card.scss";

import * as R from "ramda";

import { node, string } from "prop-types";

import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import { navigate } from "gatsby";
import useAOS from "../../common/useAOS.js";
import { useContentfulSiteMap } from "../../common/contentfulSiteMap";

const propTypes = {
  title: string.isRequired,
  text: node,
  image: node,
  link: node,
};

const PartnersCard = (props) => {
  const { title, text, image, link } = props;

  const siteMap = useContentfulSiteMap();

  useAOS();

  const handleClick = (link) => {
    const path = link.to || R.path([link.pageId, "path"])(siteMap);
    if (!R.isNil(path)) {
      // for external and media links, open a in a new window
      if (link.type === "external") {
        window.open(path, "_blank");
      }
      // for internal/page links, navigate regularly
      else {
        navigate(path);
      }
    }
  };

  return (
    <div
      role="presentation"
      data-aos="fade-down"
      data-aos-offset="0"
      className="partners-card"
      tabIndex="-1"
      onClick={() => handleClick(link)}
    >
      {image && image}
      <div className="partners-card__title">{title}</div>
      {text && <div className="partners-card__description">{text}</div>}
      {link && <ContentfulLink className="partners-card__link" {...link} />}
    </div>
  );
};

PartnersCard.propTypes = propTypes;
export default PartnersCard;
