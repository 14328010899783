import "./partners-page.scss";

import * as R from "ramda";

import { graphql, useStaticQuery } from "gatsby";
import { useRef, useState } from "react";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb.jsx";
import ContainerBleed from "../Container/ContainerBleed.jsx";
import FourCardGrid from "../FourCardGrid/FourCardGrid.jsx";
import Page from "../Page/Page.jsx";
import PageBanner from "../PageBanner/PageBanner.jsx";
import PartnersCard from "../PartnersCard/PartnersCard.jsx";
import SignUp from "../SignUp/SignUp.jsx";
import TwoUpTextBanner from "../TwoUpTextBanner/TwoUpTextBanner.jsx";
import extractInfoBlock from "../../common/extractInfoBlock.js";
import extractPageBanner from "../../common/extractPageBanner.js";
import extractRichText from "../../common/extractRichText.jsx";
import extractTwoUpTextBanner from "../../common/extractTwoUpTextBanner.js";
import useScrollama from "../../common/useScrollama.js";

const propTypes = {};

const PartnersPage = (props) => {
  const contentfulData = useStaticQuery(query);

  const [gradientStop, setGradientStop] = useState(0);

  const data = R.compose(
    R.evolve({
      partnersPageBanner: extractPageBanner,
      partnersGridTitle: R.identity,
      partnersGridSubtitle: extractRichText,
      partnersGrid: (partnerCards) =>
        partnerCards
          .map((card, i, array) => extractInfoBlock(card))
          .map((card, i) => <PartnersCard key={i} {...card} />),
      partnersPageTwoUpTextBanner: extractTwoUpTextBanner,
    }),
    R.defaultTo({}),
    R.prop("contentfulPartnersPage"),
  )(contentfulData);

  const {
    id,
    partnersPageBanner,
    partnersGridTitle,
    partnersGridSubtitle,
    partnersGrid,
    partnersPageTwoUpTextBanner,
  } = data;

  const colorStop1Ref = useRef();
  const colorStop2Ref = useRef();
  const colorStop3Ref = useRef();

  /* Scrollama Effects */
  useScrollama(colorStop1Ref, {
    offset: 0.5,
    // debug: true,
    onStepEnter: (response) => setGradientStop(0),
  });

  useScrollama(colorStop2Ref, {
    offset: 0.5,
    // debug: true,
    onStepEnter: (response) => setGradientStop(1),
  });

  useScrollama(colorStop3Ref, {
    offset: 0.5,
    // debug: true,
    onStepEnter: (response) => setGradientStop(2),
  });

  return (
    <Page
      pageTitle="Partners"
      pageDescription={partnersPageBanner && partnersPageBanner?.description}
      className="partners-page"
      gradient={gradientStop}
    >
      <BreadCrumb pageId={id}>breadcrumb</BreadCrumb>
      <div ref={colorStop1Ref}>
        <PageBanner
          {...partnersPageBanner}
          accent={false}
          hasBackground={false}
        />
      </div>
      <div ref={colorStop2Ref}>
        <FourCardGrid
          title={partnersGridTitle}
          description={partnersGridSubtitle}
          cards={partnersGrid}
        />
      </div>
      <div ref={colorStop3Ref}>
        <ContainerBleed>
          <TwoUpTextBanner {...partnersPageTwoUpTextBanner} />
        </ContainerBleed>
        <ContainerBleed>
          <SignUp
            title="Join Our Movement"
            subtitle="Sign up for our emails and learn how to get involved in Vision for Justice and help transform our criminal-legal system."
          />
        </ContainerBleed>
      </div>
    </Page>
  );
};

PartnersPage.propTypes = propTypes;
export default PartnersPage;

const query = graphql`
  {
    contentfulPartnersPage(id: { eq: "7d5aee94-1557-531e-a0dc-b5fb2aa13c30" }) {
      id
      partnersPageBanner {
        ...PageBanner
      }
      partnersGridTitle
      partnersGridSubtitle {
        raw
      }
      partnersGrid {
        ...InfoBlock
      }
      partnersPageTwoUpTextBanner {
        ...TwoUpTextBanner
      }
    }
  }
`;
